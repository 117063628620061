import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '../../../../../../../environments/environment';
import appConfig from '../../../../../../config/app-config.json';

import { AppointmentService } from '../../../../../../services/appointment.service';
import { LoggerService } from '../../../../../../services/logger.service';
import { UnleashService } from 'src/app/services/unleash.service';
import { FfNewBrandLogo } from 'src/app/config/feature-flags/ff-new-brand-logo';

@Component({
	selector: 'app-appointment-zocdoc-auth',
	templateUrl: './appointment-zocdoc-auth.component.html',
	styleUrls: ['./appointment-zocdoc-auth.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class AppointmentZocdocAuthComponent implements OnInit {
	@Output() closeZocdocAuth = new EventEmitter<void>();
	public readonly ARROW_RIGHT = 'arrowRight';
	private zocdocAnonymousToken: string;
	isNewBrandLogoEnabled$ = this.unleashService.isEnabled$(FfNewBrandLogo);

	constructor(
		private appointmentService: AppointmentService,
		private logger: LoggerService,
		private router: Router,
		private unleashService: UnleashService
	) {}

	ngOnInit(): void {
		this.appointmentService.getAnonymousToken().subscribe({
			next: (token) => {
				this.zocdocAnonymousToken = token.token;
			},
			error: (error) => {
				this.logger.error('Failed to get anonymous token:', error);
			},
		});
	}

	public async authenticate() {
		const codeVerifier = await this.generateCodeVerifier();
		const codeChallenge = await this.generateCodeChallenge(codeVerifier);

		localStorage.setItem('pkce_code_verifier', codeVerifier);
		const currentUrl = this.router.url;
		console.log('currentUrl', currentUrl);
		localStorage.setItem(appConfig.localStorageKeys.zocdocRedirectStateKey, currentUrl);

		const baseUrl = environment.zocdocBaesUrl;
		const redirectUri = 'https://app.healthee.co/care-and-costs/providers';
		const clientId = `client_id=${environment.zocdocClientId}`;
		const scope = 'scope=external.appointment.read external.appointment.write offline_access';
		const audience = `audience=${environment.zocdocAudience}`;
		const challenge = `code_challenge=${codeChallenge}`;
		const method = 'code_challenge_method=S256';
		const token = `anonymous_token=${this.zocdocAnonymousToken}`;

		const url = `${baseUrl}?response_type=code&redirect_uri=${encodeURIComponent(
			redirectUri
		)}&${clientId}&${scope}&${audience}&${challenge}&${method}&${token}`;

		this.logger.debugOnDevice('AppointmentZocdocAuthComponent: should redirect to Zocdoc auth page', {
			url,
			codeVerifier,
		});
		this.emitModalCloseSignal();

		window.location.href = url;
	}

	private emitModalCloseSignal() {
		setTimeout(() => {
			this.closeZocdocAuth.emit();
		}, 100);
	}

	private async generateCodeVerifier(length: number = 32): Promise<string> {
		const array = new Uint8Array(length);
		window.crypto.getRandomValues(array);
		return Array.from(array, (byte) => byte.toString(16).padStart(2, '0')).join('');
	}

	private async generateCodeChallenge(codeVerifier: string): Promise<string> {
		const encoder = new TextEncoder();
		const data = encoder.encode(codeVerifier);
		const hash = await window.crypto.subtle.digest('SHA-256', data);
		const base64UrlHash = btoa(String.fromCharCode(...new Uint8Array(hash)))
			.replace(/\+/g, '-')
			.replace(/\//g, '_')
			.replace(/=+$/, '');
		return base64UrlHash;
	}
}
