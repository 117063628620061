<div class="medical-spending-connected" *ngIf="isConnected$ | async">
	<div class="d-flex flex-direction-row justify-content-space-between w-100">
		<div class="d-flex flex-direction-column">
			<div class="medical-spending-connected-title">
				{{ 'Your medical spendings' | translate }}

				<div *ngIf="isHRAPlan$ | async" class="deductible-info-icon-wrapper">
					<ripple-icon
						name="infoCircle"
						[size]="RIPPLE_ICON_SIZE.size12"
						[rippleTooltipWithTouch]="HRAPlanTooltipContent"
						[tooltipTitleOnMobile]="'Note' | translate"
					></ripple-icon>
				</div>

			</div>
			<app-deductible-sync class="app-deductible-sync" (isSyncing)="onDeductibleSyncStatusChanged($event)">
			</app-deductible-sync>
			<div class="medical-spending-connected-plan-title">
				<img src="/assets/images/icons/switch-contract/contract.svg" alt="Medical Plan" />
				{{ 'Medical Plan' | translate }}
			</div>
		</div>

		<button (click)="disconnect()" class="bt secondary-blue disconnect-button d-none lg-d-flex">
			<mat-icon class="material-icons-outlined">cancel</mat-icon>
			{{ 'Disconnect your plan' | translate }}
		</button>
	</div>
	<div *ngIf="isDeductibleSyncing" class="loading-indicator-wrapper">
		<app-loader size="small"></app-loader>
	</div>
	<mat-tab-group
		mat-align-tabs="start"
		[selectedIndex]="selectedTab$ | async"
		(selectedIndexChange)="selectedTab$.next($event)"
		(selectedTabChange)="checkOverflow()"
		[ngClass]="{ disabled: isDeductibleSyncing }"
	>
		<mat-tab>
			<ng-template mat-tab-label>
				<img
					[src]="
						(selectedTab$ | async) === 0
							? '/assets/images/icons/individual-active.svg'
							: '/assets/images/icons/individual-unactive.svg'
					"
					alt="Individual"
				/>

				<span
					(click)="track('Deductible tab toggle', { TabName: 'Individual' })"
					[class.medical-spending-connected-tab-selected]="(selectedTab$ | async) === 0"
					class="medical-spending-connected-tab"
					>{{ 'Individual' | translate }}</span
				>
			</ng-template>

			<div class="medical-spending-connected-slider-wrapper" #individualWrapperRef>
				<ng-container *ngIf="!isIndividualOverflowing; else individualWrapperWithSlider">
					<ng-container *ngTemplateOutlet="individualContent"></ng-container>
				</ng-container>
			</div>
		</mat-tab>
		<mat-tab>
			<ng-template mat-tab-label>
				<img
					[src]="
						(selectedTab$ | async) === 1
							? '/assets/images/icons/family-active.svg'
							: '/assets/images/icons/family-unactive.svg'
					"
					alt="My family"
				/>

				<span
					(click)="track('Deductible tab toggle', { TabName: 'My family' })"
					[class.medical-spending-connected-tab-selected]="(selectedTab$ | async) === 1"
					class="medical-spending-connected-tab"
					>{{ 'My family' | translate }}</span
				>
			</ng-template>

			<div class="medical-spending-connected-slider-wrapper" #familyWrapperRef>
				<ng-container *ngIf="!isFamilyOverflowing; else familyWrapperWithSlider">
					<ng-container *ngTemplateOutlet="familyContent"></ng-container>
				</ng-container>
			</div>
		</mat-tab>
	</mat-tab-group>

	<div>
		<button (click)="disconnect()" class="bt secondary-blue disconnect-button d-flex lg-d-none">
			<mat-icon class="material-icons-outlined">cancel</mat-icon>
			{{ 'Disconnect your plan' | translate }}
		</button>
	</div>
</div>

<ng-container *ngIf="(isConnected$ | async) === false">
	<div
		*ngIf="(hasRibbonInsurancePartner$ | async) && !(hasMedicalPlanByKayser$ | async)"
		class="medical-spending-disconnected"
	>
		<div class="medical-spending-disconnected-form-area">
			<div class="medical-spending-title space-pb-1">
				{{ 'Your medical spendings' | translate }}

				<div *ngIf="isHRAPlan$ | async" class="deductible-info-icon-wrapper">
					<ripple-icon
						name="infoCircle"
						[size]="RIPPLE_ICON_SIZE.size12"
						[rippleTooltipWithTouch]="HRAPlanTooltipContent"
						[tooltipTitleOnMobile]="'Note' | translate"
					></ripple-icon>
				</div>
			</div>
			<p class="medical-spending-connection-info">
				{{
					'Connect your insurance account to see how much more you’ll pay before the rest of your coverage kicks in.'
						| translate
				}}
			</p>

			<div class="medical-spending-plan-title">
				<img src="/assets/images/icons/switch-contract/contract.svg" alt="Medical Plan" />
				{{ 'Medical Plan' | translate }}
			</div>

			<app-healthee-content-slider [enableClickScroll]="false">
				<div class="medical-spending-deductibles-wrapper">
					<app-deductibles-card>
						<div class="medical-spending-deductibles-content">
							<mat-icon class="medical-spending-deductibles-lock-icon">lock</mat-icon>
							<div class="medical-spending-deductibles-content-title">
								<div class="medical-spending-deductibles-dot"></div>
								{{ 'In-network' | translate }}
							</div>
							<div class="medical-spending-deductibles-content-text">
								{{ (disconnectedDeductiblesData$ | async)?.inNetwork?.text }}
							</div>
							<div
								class="medical-spending-deductibles-action"
								(click)="focusOnMemberID(connectDeductiblesFormHandler)"
							>
								{{ 'Connect for full details' | translate }}
							</div>
							<mat-icon
								class="medical-spending-deductibles-action-icon"
								(click)="focusOnMemberID(connectDeductiblesFormHandler)"
								>expand_circle_down
							</mat-icon>
						</div>
					</app-deductibles-card>
					<app-deductibles-card [isRed]="true">
						<div class="medical-spending-deductibles-content">
							<mat-icon class="medical-spending-deductibles-lock-icon">lock</mat-icon>
							<div class="medical-spending-deductibles-content-title">
								<div class="medical-spending-deductibles-dot red"></div>
								{{ 'Out-of-Network' | translate }}
							</div>
							<div class="medical-spending-deductibles-content-text">
								{{ (disconnectedDeductiblesData$ | async)?.outNetwork?.text }}
							</div>
							<div
								class="medical-spending-deductibles-action"
								(click)="focusOnMemberID(connectDeductiblesFormHandler)"
							>
								{{ 'Connect for full details' | translate }}
							</div>
							<mat-icon
								class="medical-spending-deductibles-action-icon"
								(click)="focusOnMemberID(connectDeductiblesFormHandler)"
								>expand_circle_down
							</mat-icon>
						</div>
					</app-deductibles-card>
				</div>
			</app-healthee-content-slider>

			<div class="medical-spending-title space-pb-2">
				{{ 'Connect to unlock your full details' | translate }}
			</div>

			<app-connect-deductibles-form-handler #connectDeductiblesFormHandler></app-connect-deductibles-form-handler>
		</div>
		<div class="medical-spending-disconnected-deductibles-area">
			<app-deductibles-card>
				<div class="medical-spending-deductibles-content">
					<mat-icon class="medical-spending-deductibles-lock-icon">lock</mat-icon>
					<div class="medical-spending-deductibles-content-title">
						<div class="medical-spending-deductibles-dot"></div>
						{{ 'In-network' | translate }}
					</div>
					<div class="medical-spending-deductibles-content-text">
						{{ (disconnectedDeductiblesData$ | async)?.inNetwork?.text }}
					</div>
					<div class="medical-spending-deductibles-action-desktop">
						<mat-icon class="medical-spending-deductibles-action-icon">arrow_circle_left</mat-icon>
						<div class="medical-spending-deductibles-action">
							{{ 'Connect for full details' | translate }}
						</div>
					</div>
				</div>
			</app-deductibles-card>

			<app-deductibles-card [isRed]="true">
				<div class="medical-spending-deductibles-content">
					<mat-icon class="medical-spending-deductibles-lock-icon">lock</mat-icon>
					<div class="medical-spending-deductibles-content-title">
						<div class="medical-spending-deductibles-dot red"></div>
						{{ 'Out-of-Network' | translate }}
					</div>
					<div class="medical-spending-deductibles-content-text">
						{{ (disconnectedDeductiblesData$ | async)?.outNetwork?.text }}
					</div>
					<div class="medical-spending-deductibles-action-desktop">
						<mat-icon class="medical-spending-deductibles-action-icon">arrow_circle_left</mat-icon>
						<div class="medical-spending-deductibles-action">
							{{ 'Connect for full details' | translate }}
						</div>
					</div>
				</div>
			</app-deductibles-card>
		</div>
	</div>

	<div
		*ngIf="(hasRibbonInsurancePartner$ | async) === false || (hasMedicalPlanByKayser$ | async)"
		class="medical-spending-disconnected without-form"
	>
		<div class="medical-spending-title space-pb-1">
			{{ 'Your medical spendings' | translate }}

			<div *ngIf="isHRAPlan$ | async" class="deductible-info-icon-wrapper">
				<ripple-icon
					name="infoCircle"
					[size]="RIPPLE_ICON_SIZE.size12"
					[rippleTooltipWithTouch]="HRAPlanTooltipContent"
					[tooltipTitleOnMobile]="'Note' | translate"
				></ripple-icon>
			</div>
		</div>

		<div class="medical-spending-plan-title">
			<img src="/assets/images/icons/switch-contract/contract.svg" alt="Medical Plan" />
			{{ 'Medical Plan' | translate }}
		</div>

		<div class="medical-spending-disconnected-slider-wrapper">
			<app-healthee-content-slider appearance="standard" [enableClickScroll]="false">
				<div class="medical-spending-deductibles-wrapper">
					<app-deductibles-card>
						<div class="medical-spending-deductibles-content">
							<div class="medical-spending-deductibles-content-title">
								<div class="medical-spending-deductibles-dot"></div>
								{{ 'In-network' | translate }}
							</div>
							<div class="medical-spending-deductibles-content-text">
								{{ (disconnectedDeductiblesData$ | async)?.inNetwork?.text }}
							</div>
						</div>
					</app-deductibles-card>
					<app-deductibles-card [isRed]="true">
						<div class="medical-spending-deductibles-content">
							<div class="medical-spending-deductibles-content-title">
								<div class="medical-spending-deductibles-dot red"></div>
								{{ 'Out-of-Network' | translate }}
							</div>
							<div class="medical-spending-deductibles-content-text">
								{{ (disconnectedDeductiblesData$ | async)?.outNetwork?.text }}
							</div>
						</div>
					</app-deductibles-card>
				</div>
			</app-healthee-content-slider>
		</div>
	</div>
</ng-container>

<ng-template #individualWrapperWithSlider>
	<app-healthee-content-slider [enableClickScroll]="false">
		<ng-container *ngTemplateOutlet="individualContent"></ng-container>
	</app-healthee-content-slider>
</ng-template>

<ng-template #familyWrapperWithSlider>
	<app-healthee-content-slider [enableClickScroll]="false">
		<ng-container *ngTemplateOutlet="familyContent"></ng-container>
	</app-healthee-content-slider>
</ng-template>

<ng-template #individualContent>
	<div class="medical-spending-deductibles-wrapper" #individualRef>
		<app-medical-spending-connected-deductible-card
			[outOfPocketSpend]="
				(connectedDeductiblesData$ | async)?.data?.outOfPocketDetail?.individual?.inNetwork?.spend
			"
			[outOfPocketTotal]="
				(connectedDeductiblesData$ | async)?.data?.outOfPocketDetail?.individual?.inNetwork?.maximum
			"
			[spend]="(connectedDeductiblesData$ | async)?.data?.deductibleDetail?.individual?.inNetwork?.spend"
			[remaining]="(connectedDeductiblesData$ | async)?.data?.deductibleDetail?.individual?.inNetwork?.remaining"
			[total]="(connectedDeductiblesData$ | async)?.data?.deductibleDetail?.individual?.inNetwork?.maximum"
		></app-medical-spending-connected-deductible-card>

		<app-medical-spending-connected-deductible-card
			[isRed]="true"
			[outOfPocketSpend]="
				(connectedDeductiblesData$ | async)?.data?.outOfPocketDetail?.individual?.outOfNetwork?.spend
			"
			[outOfPocketTotal]="
				(connectedDeductiblesData$ | async)?.data?.outOfPocketDetail?.individual?.outOfNetwork?.maximum
			"
			[spend]="(connectedDeductiblesData$ | async)?.data?.deductibleDetail?.individual?.outOfNetwork?.spend"
			[remaining]="
				(connectedDeductiblesData$ | async)?.data?.deductibleDetail?.individual?.outOfNetwork?.remaining
			"
			[total]="(connectedDeductiblesData$ | async)?.data?.deductibleDetail?.individual?.outOfNetwork?.maximum"
		></app-medical-spending-connected-deductible-card>
	</div>
</ng-template>

<ng-template #familyContent>
	<div class="medical-spending-deductibles-wrapper" #familyRef>
		<app-medical-spending-connected-deductible-card
			[outOfPocketSpend]="(connectedDeductiblesData$ | async)?.data?.outOfPocketDetail?.family?.inNetwork?.spend"
			[outOfPocketTotal]="
				(connectedDeductiblesData$ | async)?.data?.outOfPocketDetail?.family?.inNetwork?.maximum
			"
			[spend]="(connectedDeductiblesData$ | async)?.data?.deductibleDetail?.family?.inNetwork?.spend"
			[remaining]="(connectedDeductiblesData$ | async)?.data?.deductibleDetail?.family?.inNetwork?.remaining"
			[total]="(connectedDeductiblesData$ | async)?.data?.deductibleDetail?.family?.inNetwork?.maximum"
		></app-medical-spending-connected-deductible-card>

		<app-medical-spending-connected-deductible-card
			[isRed]="true"
			[outOfPocketSpend]="
				(connectedDeductiblesData$ | async)?.data?.outOfPocketDetail?.family?.outOfNetwork?.spend
			"
			[outOfPocketTotal]="
				(connectedDeductiblesData$ | async)?.data?.outOfPocketDetail?.family?.outOfNetwork?.maximum
			"
			[spend]="(connectedDeductiblesData$ | async)?.data?.deductibleDetail?.family?.outOfNetwork?.spend"
			[remaining]="(connectedDeductiblesData$ | async)?.data?.deductibleDetail?.family?.outOfNetwork?.remaining"
			[total]="(connectedDeductiblesData$ | async)?.data?.deductibleDetail?.family?.outOfNetwork?.maximum"
		></app-medical-spending-connected-deductible-card>
	</div>
</ng-template>

<ng-template #HRAPlanTooltipContent>
	{{ 'Your HRA can help cover costs for doctor visits, prescriptions, and more. Check your plan details for specifics.' | translate }}
</ng-template>
